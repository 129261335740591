<template>
  <Product
    :featureImageUrl="product.itinerary.featureImageUrl"
    :heroImageUrl="product.itinerary.heroImageUrl"
    :country="product.itinerary.targetCountryName"
    :title="product.itinerary.title"
    :isMba="product.itinerary.mbaExclusive"
    :externalLink="externalLink || product.meta.link"
    :spotsLeft="product.event.spotsLeft"
    :product="product"
    :trackingHash="trackingHash"
    :openInNewTab="openInNewTab"
  >
    <template #productMeta>
      <div class="product__price">
        <span>From </span>
        <Money
          :currency="product.itinerary.baseCurrency"
          :options="{ maximumFractionDigits: 0, currencyDisplay: 'code' }"
          :value="product.event.lowestAmountCents"
          inline
        />
      </div>
      <div class="product__date">
        {{ eventDate(product.event.startsAt, product.event.timezone) }} -
        <span>
          {{ t('nights', { count: product.itinerary.durationNights }, product.itinerary.durationNights) }}
        </span>
      </div>
    </template>
  </Product>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";
import Product from './Product2/index.vue';

import { formatDate } from '@/utils/dateUtils.ts';
import Money from '@/components/Money.vue';

const { t } = useShared();

defineProps({
  product: Object,
  booking: Object,
  externalLink: String,
  trackingHash: { type: Object, default: () => ({}) },
  openInNewTab: { type: Boolean, default: false }
})

function eventDate(date, timezone) {
  if (date && timezone) {
    return formatDate(date, timezone, 'MMM D, YYYY');
  }
}
</script>

<style lang="scss" scoped>
.product__price {
  color: $brand-secondary;
  font-size: 14px;
  line-height: 16px;
}

.product__date {
  color: $gray;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
</style>

<i18n lang="yaml">
en:
  loading: Loading ...
  trips_available: 'No trip available | {count} trip available | {count} trips available'
  mba_trip: MBA Trip
  nights: '{count} night | {count} nights'
</i18n>
